/*./src/index.css*/
@import '~react-toastify/dist/ReactToastify.min.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

/*

@todo: přidat css do komponenty jako css modul
https://create-react-app.dev/docs/adding-a-css-modules-stylesheet/
 */
.custom-search-select .custom-search-select__input input {
  box-shadow: none;
}

button[disabled] {
  opacity: 0.7;
}

.chat-message a {
  text-decoration: underline;
}
